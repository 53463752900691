<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Flujo de Ventas - Detalle Potencial Cliente</h4>
            <div class="small text-muted">Vista detallada del potencial cliente.</div>
          </b-col>                  
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">        
        <b-row>
          <b-col>

          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>              
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.FLUJO_VENTAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'detailLeads',
          elements: {}
        },             
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.load()
    },
    methods: {      
      load() {
        /*
        var result = serviceAPI.obtenerLeadsCategories()

        result.then((response) => {
          var data = response.data
          this.table.items = data
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
        */
      },
    }    
  }
</script>